
import person_input from './person_input.vue'

export default {
  props: {
    is_allow_create_new: {type: Boolean, default: true}
    prefix: {type: String, required: true}
    errors: {type: Object, required: true}
  }

  components: {
    person_input: person_input
  }

  mounted: () ->
    @add_row()
    @validate_rows()

  data: () ->
    return {
      rows: []
    }

  methods: {
    add_row: () ->
      @rows.push({
        name: undefined
        mail: undefined
        phone: undefined
      })

    validate_row: (row, prefix) ->
      for field_name, value of row
        field_key = prefix + field_name
        if field_name != 'phone' and (not value? or value.trim().length == 0)
          @errors[field_key] = true
        else if field_key of @errors
          delete @errors[field_key]
      return

    validate_rows: () ->
      for row, index in @rows
        @validate_row(row, "#{@prefix}#{index}_")
      return
  }

  watch: {
    rows: {
      handler: () ->
        @validate_rows()
      deep: true
    }
  }

  computed: {
    columns: () ->
      return [{
        title: 'Ф.И.О.'
        required: true
      }, {
        title: 'Электронная почта'
        required: true
      }, {
        title: 'Телефон'
        required: false
      },]
  }
}
