
import modal from './../layouts/modal.vue'
import group_person_inputs from './../layouts/group_person_inputs.vue'
import form_sended from './../icons/form_sended.svg'
import helpers from './../helpers/common.coffee'
import confirmed_modal from './confirmed_modal.vue'

export default {
  props: {
    name: {type: String, required: true}
    current_value: {type: Object, required: true}
  }

  components: {
    modal: modal
    group_person_inputs: group_person_inputs
    form_sended: form_sended
    confirmed_modal: confirmed_modal
  }

  data: () ->
    return {
      confirmed: false
      person_agreed: false
      errors: {}
    }

  computed: {
    have_errors: () ->
      return Object.keys(@errors).length > 0 or not @person_agreed
  }

  methods: {
    handler_closed: (value) ->
      if value and not @confirmed
        contact = @$refs.contact.rows[0]
        tutors = @$refs.tutors.rows
        if @have_errors
          return
        helpers.send_query({
          aliasName: @name
          start_date: @current_value.start_date
          contact: contact
          tutors: @$refs.tutors.rows
        })
        @confirmed = true
      else
        @$emit('modal_closed', @confirmed)
      return
  }

}
