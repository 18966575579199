
import vue_multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

import window_helpers from './../helpers/window.coffee'
import booking from './../components/booking.vue'
import enum_arrow from './../icons/enum_arrow.svg'
import ekra_button from './../layouts/button.vue'
import next_button from './../icons/next_button.svg'

export default {
  props: {
    items: {type: Object, default: undefined}
  }

  components: {
    vue_multiselect: vue_multiselect
    enum_arrow: enum_arrow
    booking: booking
    ekra_button: ekra_button
    next_button: next_button
  }

  data: () ->
    return {
      current_value: undefined
      is_show_booking: false
    }

  mounted: () ->
    @calc_init_enum()

  methods: {
    make_link: (page_name) ->
      return ['', page_name]

    move_to_page: (page_name, event) ->
      if not event.ctrlKey
        window_helpers.move_to_path([page_name])
        event.preventDefault()
        event.stopPropagation()
      return

    handler_closed: (value) ->
      @is_show_booking = false

    calc_init_enum: () ->
      if @dates?
        @current_value = Object.keys(@dates)[0]
      else
        @current_value = undefined
      return

    handler_button: () ->
      if @course?
        window.open(@course.link)
      return

    handler_click_arrow_button: (link, event) ->
      if not event.ctrlKey
        window_helpers.move_to_path(link)
        event.preventDefault()
        event.stopPropagation()
      return
  }

  computed: {
    previous_link: () ->
      allow_indexes = Object.keys(@items)
      index = allow_indexes.indexOf(@course_name)
      if index == 0
        new_index = allow_indexes.length - 1
      else
        new_index = index - 1
      return @make_link(allow_indexes[new_index])

    next_link: () ->
      allow_indexes = Object.keys(@items)
      index = allow_indexes.indexOf(@course_name)
      if (index + 1) == allow_indexes.length
        new_index = 0
      else
        new_index = index + 1
      return @make_link(allow_indexes[new_index])

    slider_width: () ->
      return (Object.keys(@items).length * (72) - 12) + 'px'

    course_data: () ->
      if not @course?
        return {}
      return {
        education_format: {
          label: 'Форма обучения'
          value: @course.education_format
        }
        hours: {
          label: 'Срок освоения'
          value: @course.hours + ' часов'
        }
        description: {
          label: 'Цель'
          value: @course.description
        }
      }

    label_for_button: () ->
      return 'Описание образовательной программы'

    course_name: () ->
      return @$route.params.course

    img_src: () ->
      if @course?
        return require('./../assets/courses/' + @course.image)
      else
        return undefined

    course: () ->
      if @course_name? and @items?
        return @items[@course_name]
      return undefined

    name: () ->
      if @course?
        return @course.aliasName
      return 'Эксплуатация наукоемкого оборудования НПП ЭКРА подразумевает ' +
        'регулярное повышение квалификации специалистов. <br /><br />' +
        'Научно-образовательный центр ЭКРА реализует ' +
        'практико-ориентированные образовательные программы на ' +
        'собственной лабораторной базе.'

    dates: () ->
      result = {}
      if @course?
        for date, index in @course.dates
          if typeof date.empty_places == 'number' and date.empty_places < 0
            date.empty_places = 0
          result[date.start_date] = date
        return result
      return undefined

    adapted_select_value: () ->
      return @dates[@current_value]

    simple_inputs: () ->
      return {
        end_date: {
          label: 'Дата окончания'
          value: @adapted_select_value.end_date
        }
        empty_places: {
          label: 'Свободно мест'
          value: @adapted_select_value.empty_places
        }
        busy_places: {
          label: 'Занято мест'
          value: @adapted_select_value.busy_places
        }
      }
  }

  watch: {
    course_name: (new_value) ->
      @calc_init_enum()

    current_value: (new_value, old_value) ->
      if not new_value?
        setTimeout(() =>
          @current_value = old_value
        )
      return
  }
}
