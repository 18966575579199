
import remove_row_button from './../icons/remove_row_button.svg'

EXPRESSION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  props: {
    is_allow_create_new: {type: Boolean, default: true}
    is_first_row: {type: Boolean, default: false}
    row: {type: Object, required: true}
    index: {type: Number, required: true}
    prefix: {type: String, required: true}
    errors: {type: Object, required: true}
  }

  components: {
    remove_row_button: remove_row_button
  }

  data: () ->
    return {
      error_msg: ""
    }

  methods: {
    handler_end_of_edit: () ->
      phoneObject = @$refs.phone_input.phoneObject
      if not phoneObject.valid
        value = null
      else
        value = phoneObject.formatted
      @phone = value

    handler_input_phone: (value, obj, el) ->
      @phone = value

    calc_input_class: (prop_name) ->
      result = ["input_container"]
      if "#{@prefix}#{@index}_#{prop_name}" of @errors
        result.push("error")
        @error_msg = "Обязательно для заполнения"
      else
        if prop_name == "mail"
          if not EXPRESSION_REGEX.test(@row.mail)
            @error_msg = "Неправильный адрес почты"
            @errors["mail_valid"] = true
            result.push("error")
          else
            @error_msg = ""
            delete @errors["mail_valid"]
      return result
  }

  computed: {
    is_show_delete: () ->
      return not @is_first_row and @is_allow_create_new

    countries_list: () ->
      return ['RU', 'BY']
  }

  data: () ->
    return {phone: undefined}
}
